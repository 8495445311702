@import "colors.sass";

$borderColor: lightgray;
$black: #000000;

.nodeList {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none; } }

.load-error {
  margin: 1em;
  border: 1px solid $red;
  padding: 1em;
  display: inline-flex;
  align-items: center;

  svg {
    fill: $red;
    margin-right: 1em; } }


.node {
  width: 100%;
  border-bottom: 1px solid $borderColor;
  border-left: 3px solid transparent;

  &.info {
    border-left-color: $green; }

  &.error, &.critical {
    border-left-color: $red; }

  &.warning {
    border-left-color: $orange; }

  &:first-child {
    border-top: 1px solid $borderColor; }

  .wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    font-family: sans-serif;
    color: $black;
    text-decoration: none;
    align-items: center;

    .icon {
      grid-row: 1 /span 2;
      font-size: 2em;
      display: flex;
      align-items: center;
      padding: 5px; }

    .status {
      grid-column: 2;
      color: gray; }

    .status, .name {
      padding: 5px; } } }

.nodeListContainer {
  display: flex;
  flex-direction: column;
  .nodeList {
    flex-grow: 1; }

  .showInactiveButton {
    text-align: center;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 10px;
    color: lighten($black, 30); } }
