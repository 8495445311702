.App {
  display: grid;
  grid-template-rows: auto 1fr auto; }

.App > div {
  overflow: auto; }

.App-header {
  background-color: #222;
  padding: 10px;
  color: white;

  h1 {
    font-size: 1.5em;
    margin: 0; } }

.multiCol {
  display: flex;
  flex-direction: row;
  &>:first-child {
    width: 33%; }
  &>:last-child {
    width: 66%;
    border-left: 1px solid gray; }
  &>:only-child {
    width: 100%; } }
