@import "colors.sass";

.node-details {
  padding: 1em;
  background-color: #f5f5f5;

  .status {
    padding: 0;
    margin-top: 2em;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin-top: .5em;

      svg {
        margin-right: .5em; } } }

  .critical-explanation {
    margin-left: 2em;
    padding-left: .5em;
    border-left: 2px solid $red;
    margin-bottom: 2em; } }
