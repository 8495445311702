.node-charts {
  padding: 1em;
  overflow: scroll; }

.charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }


.chartGroup {
  border: 1px solid lightgray;
  margin-top: 2em;
  display: grid; }
