@import "colors.sass";

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

body, html, #root, .App {
  height: 100%; }

body {
  background: $gray; }
